import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { AuthProvider, AuthStatus, useAuth } from "Auth";
import { Auth } from "aws-amplify";
// import ReactGA4 from 'react-ga4';

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Script } from "Script";
import "./App.css";
import { BlockModal } from "components/auth/blockModal";
import { ConfirmAccount } from "components/auth/confirm-account";
import { ConfirmForgotPassword } from "components/auth/confirm-forgot-password";
import { CreateAccount } from "components/auth/create-account";
import { ForgotPassword } from "components/auth/forgot-password";
import { Login } from "components/auth/login";
import { BillingSuccessScreen } from "screens/billing-success-screen";
import { EnvironmentScreen } from "screens/environments/environment";
import { Header } from "components/layout/header";
import { Navbar } from "components/layout/navbar";
import { Footer } from "components/layout/footer";
import { ApplicationScreen } from "screens/applications/application-screen";
import { ApplicationsScreen } from "screens/applications/applications-screen";
import { BillingScreen } from "screens/billing/billing-screen";
import { ContentScreen } from "screens/content/content-screen";
import { ContentsScreen } from "screens/content/contents-screen";
import { WidgetsScreen } from "screens/widget/widgets-screen";
import { WidgetScreen } from "screens/widget/widget-screen";
import { WidgetCreateScreen } from "screens/widget/widgetcreate-screen";
import { HomeScreen } from "screens/home-screen";
import { FigmaScreen } from "screens/plugins/figma-screen";
import { IntegrationsScreen } from "screens/integrations-screen";
import { StatusScreen } from "screens/status-screen";
import { UsersScreen } from "screens/users/users-screen";
import { VersionScreen } from "screens/version-screen";
import { WorkflowsScreen } from "screens/workflows-screen";
import { AccountSettings } from "screens/account-settings";
import { PlayGroundScreen } from "screens/playground-screen";
import { ApiKeyScreen } from "screens/apiKey/apikey-screen";
import NavbarTour from "components/utils/navbar-tour";

import {
  fetchTranslationData,
  fetchAvailableLanguages,
  getLanguage,
  TranslatedDataInterface,
  ILanguage,
  loadCacheTranslatedData,
  checkLanguage,
  getValidLanguage,
  saveLanguage,
} from "utils/languages";
import { MyTeamScreen } from "screens/myteam/myteam-screen";
import { AdobeXDScreen } from "screens/plugins/adobexd-screen";
import { PageLoading } from "components/page/page-loading";

// ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);

const AppContainer = styled.div`
  display: flex;
  height: 1px;
  flex-grow: 1;
`;
const AppContent = styled.div`
  width: 1px;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`;
const AppMain = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const TenantContext = React.createContext("");

interface LanguageContextType {
  language: string;
  translatedData: TranslatedDataInterface;
  languages: ILanguage[];
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}
export const LanguageContext = React.createContext<LanguageContextType>(null!);
export const useTranslation = () => React.useContext(LanguageContext);

function RequireAuth({ children }: any) {
  const location = useLocation();
  const { auth } = useAuth();

  if (auth.authStatus === AuthStatus.pending) {
    return <Spinner />;
  }

  if (auth.authStatus === AuthStatus.signedOut) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <AppContainer>
        <NavbarTour type="auto" />
        <Navbar />
        {(auth?.user?.attributes["custom:isOnboarded"] === "false" ||
          !auth?.user?.attributes["custom:isOnboarded"]) && (
          <BlockModal isOpen={true} />
        )}
        <AppContent>{children}</AppContent>
      </AppContainer>
      <Footer />
    </>
  );
}

function MainRoute() {
  const { auth } = useAuth();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <HomeScreen />
            </RequireAuth>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/confirm-account" element={<ConfirmAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/confirm-forgot-password"
          element={<ConfirmForgotPassword />}
        />
        <Route
          path="/figma"
          element={
            <RequireAuth>
              <FigmaScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/adobe-xd"
          element={
            <RequireAuth>
              <AdobeXDScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <AccountSettings />
            </RequireAuth>
          }
        />
        <Route
          path="/apikey"
          element={
            <RequireAuth>
              <ApiKeyScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/content"
          element={
            <RequireAuth>
              <ContentsScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/content/:contentId"
          element={
            <RequireAuth>
              <ContentScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/content/:contentId/versions/:versionId"
          element={
            <RequireAuth>
              <VersionScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/widget"
          element={
            <RequireAuth>
              <WidgetsScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/widget/create"
          element={
            <RequireAuth>
              <WidgetCreateScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/widget/:widgetId"
          element={
            <RequireAuth>
              <WidgetScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/widget/create/:widgetId"
          element={
            <RequireAuth>
              <WidgetCreateScreen />
            </RequireAuth>
          }
        />
        <Route
          path="users"
          element={
            <RequireAuth>
              <UsersScreen />
            </RequireAuth>
          }
        />
        <Route
          path="workflows"
          element={
            <RequireAuth>
              <WorkflowsScreen />
            </RequireAuth>
          }
        />
        <Route
          path="applications"
          element={
            <RequireAuth>
              <ApplicationsScreen />
            </RequireAuth>
          }
        />
        <Route
          path="applications/:applicationId"
          element={
            <RequireAuth>
              <ApplicationScreen />
            </RequireAuth>
          }
        />
        <Route
          path="applications/:applicationId/environments/:environmentId"
          element={
            <RequireAuth>
              <EnvironmentScreen />
            </RequireAuth>
          }
        />

        <Route
          path="billing"
          element={
            <RequireAuth>
              <BillingScreen />
            </RequireAuth>
          }
        />
        <Route
          path="billing/success"
          element={
            <RequireAuth>
              <BillingSuccessScreen />
            </RequireAuth>
          }
        />
        {auth?.role === "owner" && (
          <Route
            path="myteam"
            element={
              <RequireAuth>
                <MyTeamScreen />
              </RequireAuth>
            }
          />
        )}
        <Route
          path="playground"
          element={
            <RequireAuth>
              <PlayGroundScreen />
            </RequireAuth>
          }
        />
        <Route
          path="integrations"
          element={
            <RequireAuth>
              <IntegrationsScreen />
            </RequireAuth>
          }
        />
        <Route
          path="status"
          element={
            <RequireAuth>
              <StatusScreen />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
}

function App() {
  const [tenant, setTenant] = useState("");
  const [language, setLanguage] = useState(getLanguage());
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [loadingLang, setLoadingLang] = useState(true);
  const [translatedData, setTranslatedData] = useState(
    loadCacheTranslatedData()
  );

  const fetchData = async () => {
    const { idToken } = (await Auth.currentSession()) as any;
    setTenant(idToken.payload["custom:tenantId"]);
  };

  const fetchLanguages = useCallback(async () => {
    const lang_res = await fetchAvailableLanguages(language);
    setLanguages(lang_res);

    let _language = language;
    if (!checkLanguage(lang_res, language)) {
      _language = getValidLanguage(lang_res, language);
    }
    if (language !== _language) {
      setLanguage(_language);
    }
    saveLanguage(_language);
  }, [language]);

  const fetchTranslations = useCallback(async () => {
    setLoadingLang(true);
    if (!language) return;
    const data = await fetchTranslationData(language);
    if (!Object.keys(data).length) return;
    setTranslatedData(data);
    setLoadingLang(false);
    console.log(data);
  }, [language]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchLanguages();
    fetchTranslations();
  }, [language]);

  return (
    <AppMain>
      <Script />
      <TenantContext.Provider value={tenant}>
        <LanguageContext.Provider
          value={{ language, setLanguage, languages, translatedData }}
        >
          <AuthProvider>
            <ChakraProvider>
              {loadingLang ? <PageLoading /> : <MainRoute />}
            </ChakraProvider>
          </AuthProvider>
        </LanguageContext.Provider>
      </TenantContext.Provider>
    </AppMain>
  );
}

export default App;
