import React, { useState, useContext, useEffect } from "react";
import { Button } from "@chakra-ui/button";
import queryString from "querystring";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
  UnorderedList,
  ListItem,
  Box,
} from "@chakra-ui/react";
import validator from "validator";
import { useAuth } from "Auth";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LanguageContext } from "App";
import { Layout } from "./layout";
import PasswordInput from "components/utils/password-input";

const OrContainer = styled.div`
  margin: 50px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  color: rgb(105, 120, 135);
  font-size: 14px;
`;

const Or = styled.div`
  position: relative;
  top: -8px;
  display: inline-block;
  padding: 0px 20px;
  background: rgb(255, 255, 255);
`;
const Spacer = styled.div`
  height: 20px;
`;
const ToastWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

export function ConfirmForgotPassword() {
  const { translatedData } = useContext(LanguageContext);
  const location = useLocation();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState<any>({ email: "" });
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState("");
  const [authCode, setAuthCode] = useState<any>("");

  useEffect(() => {
    if (!location) return;
    const searchQuery = location.search.replace("?", "");
    const params = queryString.parse(searchQuery);
    console.log("query params", params);
    if (params.email) setEmail(params.email);
    if (params.authCode) setAuthCode(params.authCode);
    setQueryParams(params);
  }, [location]);

  const getCustomException = (validate: any) => {
    const keys = Object.keys(validate);
    if (!keys.some((key) => validate[key])) return null;
    return (
      <ToastWrapper>
        <Alert status="warning">
          <AlertIcon />
          <AlertDescription>
            <UnorderedList>
              {keys.map((key) => (
                <ListItem
                  key={key}
                  color={validate[key] ? "red.500" : "green.500"}
                >
                  {translatedData[key]}
                </ListItem>
              ))}
            </UnorderedList>
          </AlertDescription>
        </Alert>
      </ToastWrapper>
    );
  };

  const getCustomExceptionMessage = (password: string) => {
    const passwordValidate = {
      "passwordempty-exception": false,
      "signup-passwordlength-exception": false,
      "signup-invalidpasswordspace-exception": false,
    };

    const spaceValidateRegex = /^\S+.*\S+$/;

    if (validator.isEmpty(password))
      passwordValidate["passwordempty-exception"] = true;
    if (!validator.isLength(password, { min: 8 }))
      passwordValidate["signup-passwordlength-exception"] = true;
    if (
      !validator.matches(password, spaceValidateRegex) ||
      password.includes(" ")
    )
      passwordValidate["signup-invalidpasswordspace-exception"] = true;

    const customException = getCustomException(passwordValidate);
    return customException;
  };

  const showExceptionToast = (err: any, password: string) => {
    const customException = getCustomExceptionMessage(password);

    let customMessageKey = "";
    if (err?.name === "CodeMismatchException")
      customMessageKey = "signup-codemismatch-exception";
    if (err?.message === "Confirmation code cannot be empty")
      customMessageKey = "authcodeempty-exception";
    const customDescription =
      !customException && (translatedData[customMessageKey] || err?.message);

    toast({
      // title: 'Error',
      description: customDescription,
      position: "top",
      status: "error",
      duration: 5000,
      isClosable: true,
      render: customException ? () => customException : undefined,
    });
  };
  const handleForgotPassword = async () => {
    if (getCustomExceptionMessage(password)) {
      return showExceptionToast(null, password);
    }
    setIsLoading(true);
    try {
      await auth.forgotPasswordSubmit(email, authCode, password);
      setIsLoading(false);
      navigate("/");
    } catch (err: any) {
      showExceptionToast(err, password);
      setIsLoading(false);
    }
  };
  const resendAuthCode = async () => {
    setIsLoading(true);
    try {
      await auth.forgotPassword(email);
      setIsLoading(false);
    } catch (err: any) {
      console.log(err.name, err.message);
      setIsLoading(false);
    }
  };

  const inputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (type === "email") {
      setEmail(e.target.value);
    } else if (type === "password") {
      setPassword(e.target.value);
    } else if (type === "authCode") {
      setAuthCode(e.target.value);
    }
  };
  return (
    <Layout>
      <Stack spacing={12} width="full" maxW="md">
        <Heading fontSize="24px" textAlign="center">
          {translatedData["update-password"]}
        </Heading>
        <Box textAlign="center">
          <FormControl id="text">
            <FormLabel>{translatedData["email-address"]}</FormLabel>
            <Input
              value={email}
              placeholder={translatedData["email-address"]}
              disabled={!!queryParams?.email}
              onChange={(e) => inputChange(e, "email")}
            />
            {/* <FormHelperText>Enter translation text.</FormHelperText> */}
          </FormControl>
          <Spacer />
          <FormControl id="text">
            <FormLabel>{translatedData["auth-code"]}</FormLabel>
            <Input
              value={authCode}
              placeholder={translatedData["auth-code"]}
              onChange={(e) => inputChange(e, "authCode")}
            />
            {/* <FormHelperText>Enter translation text.</FormHelperText> */}
          </FormControl>
          <Spacer />
          <FormControl id="text">
            <FormLabel>{translatedData["new-password"]}</FormLabel>
            <PasswordInput
              value={password}
              placeholder="********"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                inputChange(e, "password")
              }
            />
          </FormControl>
          <Spacer />
          <Button
            type="submit"
            colorScheme="pink"
            style={{ width: "100%" }}
            isLoading={isLoading}
            onClick={handleForgotPassword}
          >
            {translatedData["save-and-continue"]}
          </Button>
          <Button
            variant="link"
            color="blue.500"
            size="sm"
            onClick={resendAuthCode}
          >
            {translatedData["resend-auth-code"]}
          </Button>
          <Stack spacing="2" display={{ base: "flex", lg: "none" }}>
            <OrContainer>
              <Or>OR</Or>
            </OrContainer>
            <Button
              colorScheme="gray"
              style={{ width: "100%" }}
              onClick={() => navigate("/login")}
            >
              {translatedData["sign-in"]}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
}
